var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "my-4" },
        [
          _c("v-col", { staticClass: "text-h5", attrs: { cols: "12" } }, [
            _vm._v("Delete Assessment and associated data")
          ]),
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  label: "assessment id",
                  rules: [_vm.rules.assessmentIdRequired]
                },
                model: {
                  value: _vm.assessmentIdToDelete,
                  callback: function($$v) {
                    _vm.assessmentIdToDelete = $$v
                  },
                  expression: "assessmentIdToDelete"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "red",
                    loading: _vm.deleteAssessmentInProgress
                  },
                  on: { click: _vm.deleteAssessmentFromId }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v(" mdi-delete-variant ")
                  ]),
                  _vm._v(" Delete ")
                ],
                1
              )
            ],
            1
          ),
          _c("v-col", { staticClass: "text-h5", attrs: { cols: "12" } }, [
            _vm._v("Update Desk Assessment Scores")
          ]),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mb-3",
                  attrs: { color: "red", loading: _vm.updateInProgress },
                  on: { click: _vm.updateAllScores }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v(" mdi-update ")
                  ]),
                  _vm._v(" Update ")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-row",
        { staticClass: "my-4" },
        [
          _c("v-col", { staticClass: "text-h5", attrs: { cols: "12" } }, [
            _vm._v("Transfer Users To Another Team")
          ]),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  label: "Users will be moved away from this team",
                  hint: "Must enter Team ID in Guid form"
                },
                model: {
                  value: _vm.transferFromTeamId,
                  callback: function($$v) {
                    _vm.transferFromTeamId = $$v
                  },
                  expression: "transferFromTeamId"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  label: "Users will be moved into this team",
                  hint: "Must enter Team ID in Guid form"
                },
                model: {
                  value: _vm.transferToTeamId,
                  callback: function($$v) {
                    _vm.transferToTeamId = $$v
                  },
                  expression: "transferToTeamId"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "secondary",
                    loading: _vm.migrateLoginsInProgress
                  },
                  on: {
                    click: function($event) {
                      return _vm.moveUsersToNewTeam()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v(" mdi-arrow-decision-outline ")
                  ]),
                  _vm._v(" Migrate ")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "text-h5", attrs: { cols: "12" } }, [
            _vm._v("Update assessment question type")
          ]),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("v-text-field", {
                attrs: { outlined: "", label: "Name" },
                model: {
                  value: _vm.updateDataPoint.name,
                  callback: function($$v) {
                    _vm.$set(_vm.updateDataPoint, "name", $$v)
                  },
                  expression: "updateDataPoint.name"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("v-text-field", {
                attrs: { outlined: "", label: "New type" },
                model: {
                  value: _vm.updateDataPoint.fieldType,
                  callback: function($$v) {
                    _vm.$set(_vm.updateDataPoint, "fieldType", $$v)
                  },
                  expression: "updateDataPoint.fieldType"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "green",
                    loading: _vm.updatingAssessmentDataPoint,
                    disabled:
                      !_vm.updateDataPoint.name ||
                      !_vm.updateDataPoint.fieldType
                  },
                  on: {
                    click: function($event) {
                      return _vm.updateAssessmentDataPoint()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v(" mdi-tree ")]),
                  _vm._v(" Update ")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "secondary" },
                  on: {
                    click: function($event) {
                      _vm.updateTeamsUsersObject.fileDropOpen = !_vm
                        .updateTeamsUsersObject.fileDropOpen
                    }
                  }
                },
                [_vm._v("Upload new team file")]
              )
            ],
            1
          ),
          _vm.updateTeamsUsersObject.fileDropOpen
            ? _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("p", { staticClass: "red--text text-h6" }, [
                    _vm._v(
                      " Warning: This can currently only handle single tags per user "
                    )
                  ]),
                  _c("file-drop", {
                    staticClass: "mb-12",
                    staticStyle: { height: "250px" },
                    attrs: { maxFileSizeMb: 2 },
                    model: {
                      value: _vm.uploadedTeamFile,
                      callback: function($$v) {
                        _vm.uploadedTeamFile = $$v
                      },
                      expression: "uploadedTeamFile"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: { outlined: "", label: "team id" },
                    model: {
                      value: _vm.updateTeamsUsersObject.teamIdToUpdate,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.updateTeamsUsersObject,
                          "teamIdToUpdate",
                          $$v
                        )
                      },
                      expression: "updateTeamsUsersObject.teamIdToUpdate"
                    }
                  }),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "error",
                        loading:
                          _vm.updateTeamsUsersObject.uploadUsersInProgress,
                        disabled: !_vm.updateTeamsUsersObject.fileLoaded
                      },
                      on: {
                        click: function($event) {
                          return _vm.uploadTeamFile()
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v(" mdi-plus ")
                      ]),
                      _vm._v(" Update Team's users ")
                    ],
                    1
                  ),
                  _c("p", { staticClass: "pt-2" }, [
                    _vm._v(" Removed users not in spreadsheet: "),
                    _vm.updateTeamsUsersObject.removedUsersNotInList
                      ? _c(
                          "span",
                          [
                            _c("v-icon", { attrs: { color: "secondary" } }, [
                              _vm._v("mdi-check")
                            ])
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _c("p", [
                    _vm._v(
                      " Users uploaded / updated: " +
                        _vm._s(
                          _vm.updateTeamsUsersObject.userCounter +
                            "/" +
                            _vm.updateTeamsUsersObject.convertedUserDtos.length
                        ) +
                        " "
                    )
                  ]),
                  _vm.updateTeamsUsersObject.failedEmails.length > 0
                    ? _c(
                        "div",
                        [
                          _c("p", [_vm._v("Invalid email addresses")]),
                          _vm._l(
                            _vm.updateTeamsUsersObject.failedEmails,
                            function(email, index) {
                              return _c("p", { key: index }, [
                                _vm._v(" " + _vm._s(email) + " ")
                              ])
                            }
                          )
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _c("v-divider"),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("p", { staticClass: "text-h6" }, [_vm._v("Add translations")]),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "3", xl: "2" } },
                    [
                      _c("file-drop", {
                        staticStyle: { height: "75px" },
                        attrs: {
                          acceptedFileTypes:
                            _vm.updateTranslations.acceptedFiles,
                          maxFileSizeMb: 200,
                          small: ""
                        },
                        on: {
                          "file-error-type": function($event) {
                            return _vm.showError($event)
                          }
                        },
                        model: {
                          value: _vm.updateTranslations.translationJsonFile,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.updateTranslations,
                              "translationJsonFile",
                              $$v
                            )
                          },
                          expression: "updateTranslations.translationJsonFile"
                        }
                      }),
                      _c("p", { staticClass: "text-h6 text-center" }, [
                        _vm._v(" Locale"),
                        _vm.updateTranslations.translationJsonFile
                          ? _c("span", { staticClass: "secondary--text" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.updateTranslations.translationJsonFile
                                      .name
                                  )
                              )
                            ])
                          : _vm._e()
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3", xl: "2" } },
                    [
                      _c("file-drop", {
                        staticStyle: { height: "75px" },
                        attrs: {
                          acceptedFileTypes:
                            _vm.updateTranslations.acceptedFiles,
                          maxFileSizeMb: 200,
                          small: ""
                        },
                        on: {
                          "file-error-type": function($event) {
                            return _vm.showError($event)
                          }
                        },
                        model: {
                          value:
                            _vm.updateTranslations.translationJsonFileToAdd,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.updateTranslations,
                              "translationJsonFileToAdd",
                              $$v
                            )
                          },
                          expression:
                            "updateTranslations.translationJsonFileToAdd"
                        }
                      }),
                      _c("p", { staticClass: "text-h6 text-center" }, [
                        _vm._v(" JSON to add"),
                        _vm.updateTranslations.translationJsonFileToAdd
                          ? _c("span", { staticClass: "secondary--text" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.updateTranslations
                                      .translationJsonFileToAdd.name
                                  )
                              )
                            ])
                          : _vm._e()
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto", "align-self": "center" } },
                    [
                      _vm.updateTranslations.translationJsonFile &&
                      _vm.updateTranslations.translationJsonFileToAdd
                        ? _c(
                            "v-btn",
                            {
                              attrs: { outlined: "", color: "secondary" },
                              on: {
                                click: function($event) {
                                  return _vm.createReader(
                                    _vm.updateTranslations.translationJsonFile,
                                    _vm.jsonReadOnLoad
                                  )
                                }
                              }
                            },
                            [
                              _vm._v("Add "),
                              _c("v-icon", { attrs: { right: "", dark: "" } }, [
                                _vm._v(" mdi-plus ")
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "text-h6", attrs: { cols: "12" } }, [
            _vm._v("Send reminder emails")
          ]),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    loading: _vm.notificationReminderInProgress,
                    color: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.sendReminderNotificationEmails("paincoach")
                    }
                  }
                },
                [_vm._v("Pain Coach reminders")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    loading: _vm.notificationReminderInProgress,
                    color: "secondary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.sendReminderNotificationEmails("signup")
                    }
                  }
                },
                [_vm._v("Sign up reminders")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    loading: _vm.notificationReminderInProgress,
                    color: "tertiary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.sendReminderNotificationEmails(
                        "reassessmentReminders"
                      )
                    }
                  }
                },
                [_vm._v("Re-assessment reminders")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: "red", timeout: "-1", absolute: "", centered: "" },
          model: {
            value: _vm.showErrorMessage,
            callback: function($$v) {
              _vm.showErrorMessage = $$v
            },
            expression: "showErrorMessage"
          }
        },
        [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }