import merge from "deepmerge-json";

export default {
  addJSON(toAdd, json) {
    return merge(json, toAdd);
  },
  downloadJSON(toAdd, json, fileName) {
    var content = this.addJSON(toAdd, json);
    var a = document.createElement("a");
    var file = new Blob([JSON.stringify(content)], {
      type: "'application/json'"
    });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  }
};
