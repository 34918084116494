<template>
  <v-container fluid>
    <v-row class="my-4">
      <v-col cols="12" class="text-h5"
        >Delete Assessment and associated data</v-col
      >
      <v-col cols="4">
        <v-text-field
          outlined
          v-model="assessmentIdToDelete"
          label="assessment id"
          :rules="[rules.assessmentIdRequired]"
        />
      </v-col>
      <v-col cols="4">
        <v-btn
          @click="deleteAssessmentFromId"
          color="red"
          :loading="deleteAssessmentInProgress"
        >
          <v-icon left> mdi-delete-variant </v-icon>
          Delete
        </v-btn>
      </v-col>
      <v-col cols="12" class="text-h5">Update Desk Assessment Scores</v-col>

      <v-col cols="12">
        <v-btn
          @click="updateAllScores"
          color="red"
          :loading="updateInProgress"
          class="mb-3"
        >
          <v-icon left> mdi-update </v-icon>
          Update
        </v-btn>
      </v-col>
    </v-row>
    <v-divider> </v-divider>

    <v-row class="my-4">
      <v-col cols="12" class="text-h5">Transfer Users To Another Team</v-col>
      <v-col cols="6"
        ><v-text-field
          v-model="transferFromTeamId"
          outlined
          label="Users will be moved away from this team"
          hint="Must enter Team ID in Guid form"
        />
      </v-col>
      <v-col cols="6"
        ><v-text-field
          v-model="transferToTeamId"
          outlined
          label="Users will be moved into this team"
          hint="Must enter Team ID in Guid form"
        />
      </v-col>
      <v-col cols="12"
        ><v-btn
          @click="moveUsersToNewTeam()"
          color="secondary"
          :loading="migrateLoginsInProgress"
        >
          <v-icon left> mdi-arrow-decision-outline </v-icon>
          Migrate
        </v-btn></v-col
      >
    </v-row>

    <v-row>
      <v-col cols="12" class="text-h5">Update assessment question type</v-col>
      <v-col cols="auto"
        ><v-text-field outlined v-model="updateDataPoint.name" label="Name"
      /></v-col>
      <v-col cols="auto"
        ><v-text-field
          outlined
          v-model="updateDataPoint.fieldType"
          label="New type"
      /></v-col>
      <v-col cols="auto"
        ><v-btn
          @click="updateAssessmentDataPoint()"
          color="green"
          :loading="updatingAssessmentDataPoint"
          :disabled="!updateDataPoint.name || !updateDataPoint.fieldType"
        >
          <v-icon left> mdi-tree </v-icon>
          Update
        </v-btn></v-col
      >
    </v-row>

    <v-row class="my-4">
      <v-col cols="12"
        ><v-btn
          @click="
            updateTeamsUsersObject.fileDropOpen =
              !updateTeamsUsersObject.fileDropOpen
          "
          color="secondary"
          >Upload new team file</v-btn
        ></v-col
      >
      <v-col cols="12" v-if="updateTeamsUsersObject.fileDropOpen">
        <p class="red--text text-h6">
          Warning: This can currently only handle single tags per user
        </p>
        <file-drop
          class="mb-12"
          v-model="uploadedTeamFile"
          :maxFileSizeMb="2"
          style="height: 250px"
        />
        <v-text-field
          outlined
          v-model="updateTeamsUsersObject.teamIdToUpdate"
          label="team id"
        />
        <v-btn
          @click="uploadTeamFile()"
          color="error"
          :loading="updateTeamsUsersObject.uploadUsersInProgress"
          :disabled="!updateTeamsUsersObject.fileLoaded"
        >
          <v-icon left> mdi-plus </v-icon>
          Update Team's users
        </v-btn>

        <p class="pt-2">
          Removed users not in spreadsheet:
          <span v-if="updateTeamsUsersObject.removedUsersNotInList"
            ><v-icon color="secondary">mdi-check</v-icon></span
          >
        </p>
        <p>
          Users uploaded / updated:
          {{
            updateTeamsUsersObject.userCounter +
            "/" +
            updateTeamsUsersObject.convertedUserDtos.length
          }}
        </p>
        <div v-if="updateTeamsUsersObject.failedEmails.length > 0">
          <p>Invalid email addresses</p>
          <p
            v-for="(email, index) in updateTeamsUsersObject.failedEmails"
            :key="index"
          >
            {{ email }}
          </p>
        </div></v-col
      >
      <v-divider />
      <v-col cols="12">
        <p class="text-h6">Add translations</p>
        <v-row>
          <v-col cols="3" xl="2">
            <file-drop
              v-model="updateTranslations.translationJsonFile"
              :acceptedFileTypes="updateTranslations.acceptedFiles"
              :maxFileSizeMb="200"
              style="height: 75px"
              small
              @file-error-type="showError($event)"
            />
            <p class="text-h6 text-center">
              Locale<span
                class="secondary--text"
                v-if="updateTranslations.translationJsonFile"
              >
                {{ updateTranslations.translationJsonFile.name }}</span
              >
            </p>
          </v-col>
          <v-col cols="3" xl="2">
            <file-drop
              v-model="updateTranslations.translationJsonFileToAdd"
              :acceptedFileTypes="updateTranslations.acceptedFiles"
              :maxFileSizeMb="200"
              style="height: 75px"
              small
              @file-error-type="showError($event)"
            />
            <p class="text-h6 text-center">
              JSON to add<span
                class="secondary--text"
                v-if="updateTranslations.translationJsonFileToAdd"
              >
                {{ updateTranslations.translationJsonFileToAdd.name }}</span
              >
            </p>
          </v-col>
          <v-col cols="auto" align-self="center">
            <v-btn
              v-if="
                updateTranslations.translationJsonFile &&
                updateTranslations.translationJsonFileToAdd
              "
              @click="
                createReader(
                  updateTranslations.translationJsonFile,
                  jsonReadOnLoad
                )
              "
              outlined
              color="secondary"
              >Add <v-icon right dark> mdi-plus </v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="text-h6">Send reminder emails</v-col>
      <v-col cols="auto"
        ><v-btn
          :loading="notificationReminderInProgress"
          @click="sendReminderNotificationEmails('paincoach')"
          color="primary"
          >Pain Coach reminders</v-btn
        ></v-col
      >
      <v-col cols="auto"
        ><v-btn
          :loading="notificationReminderInProgress"
          @click="sendReminderNotificationEmails('signup')"
          color="secondary"
          >Sign up reminders</v-btn
        ></v-col
      >
      <v-col cols="auto"
        ><v-btn
          :loading="notificationReminderInProgress"
          @click="sendReminderNotificationEmails('reassessmentReminders')"
          color="tertiary"
          >Re-assessment reminders</v-btn
        ></v-col
      >
    </v-row>

    <v-snackbar
      v-model="showErrorMessage"
      color="red"
      timeout="-1"
      absolute
      centered
    >
      {{ errorMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getAllAssessmentsForUpdating,
  updateAssessmentScores,
  deleteAssessment,
  moveUsersFromOneTeamToAnother,
  updateAssesssmentDataPoint,
  updateTeamMemberForTeamDevMethod,
  removeUsersNotInListDevMethod,
  sendPainCoachRemindersTest,
  sendSignUpRemindersTest,
  sendReassessmentEmailsTest
} from "@/customApi";
import { convertDeskAssessmentData } from "@/services/modules/api-queries.js";
import DeskAssessmentResults from "@/services/deskassessment/desk-assessment-results-util.js";
import XLSX from "xlsx";
import FileDrop from "@/components/common/FileDrop.vue";
import jsonUpdate from "@/services/internal/jsonUpdate.js";

export default {
  name: "DevPage",
  components: {
    FileDrop
  },
  mounted() {
    if (
      this.userEmail !== "jamey@vitruehealth.com" &&
      this.userEmail !== "alex@vitruehealth.com" &&
      this.userEmail !== "chris@vitruehealth.com" &&
      this.userEmail !== "kevin@vitruehealth.com"
    ) {
      this.$router.push("/dashboard", () => {});
    }
  },
  data() {
    return {
      updateInProgress: false,
      deleteAssessmentInProgress: false,
      assessmentIdToDelete: "",
      errorMessage: "",
      showErrorMessage: false,
      adminTourEmail: "",
      rules: {
        teamRequired: v => !!v || "Please enter a team name",
        accountHolderRequired: v =>
          !!v || "Please enter primary account holder",
        assessmentIdRequired: v => !!v || "Please enter an assessment Id",
        adminTourEmailRequired: v => !!v || "Email address required"
      },
      transferFromTeamId: "",
      transferToTeamId: "",
      migrateTeamMembersInProgress: false,
      updatingAssessmentDataPoint: false,
      updateDataPoint: {
        name: "",
        fieldType: ""
      },
      migrateLoginsInProgress: false,
      uploadedTeamFile: [],
      convertedFile: [],
      updateTeamsUsersObject: {
        uploadUsersInProgress: false,
        removedUsersNotInList: false,
        fileDropOpen: false,
        fileLoaded: false,
        convertedUserDtos: [],
        usersStillInTeam: [],
        userCounter: 0,
        teamIdToUpdate: "",
        failedEmails: []
      },
      updateTranslations: {
        translationJsonFile: null,
        translationJsonFileToAdd: null,
        readJsonFile: null,
        readJsonFileToAdd: null,
        acceptedFiles: [".json", "application/json"]
      },
      notificationReminderInProgress: false
    };
  },
  computed: {
    ...mapGetters(["userEmail"])
  },
  watch: {
    uploadedTeamFile(newFile) {
      // if a new file has been read in, we want to parse it
      this.convertedFile = [];
      if (newFile) {
        this.fileLoaded = false;
        this.updateTeamsUsersObject.fileLoading = true;
        this.readExcelFile(newFile);
      }
    },
    convertedFile(newFile) {
      this.updateTeamsUsersObject.convertedUserDtos = [];
      this.updateTeamsUsersObject.usersStillInTeam = [];
      this.updateTeamsUsersObject.userCounter = 0;
      var currentTime = new Date().toUTCString();
      // once file is parsed we want to convert to array of dto's
      newFile.forEach((item, index) => {
        if (index !== 0) {
          this.updateTeamsUsersObject.convertedUserDtos.push({
            name: item[0],
            emailAddress: { value: item[1] },
            endUserTag: item[2],
            teamMemberTag: item[3],
            superAdmin: !!item[4],
            admin: !!item[5],
            time: currentTime
          });

          this.updateTeamsUsersObject.usersStillInTeam.push({ value: item[1] });
        }
      });
      this.updateTeamsUsersObject.fileLoaded = true;
    }
  },
  methods: {
    async sendReminderNotificationEmails(type) {
      try {
        this.notificationReminderInProgress = true;
        switch (type) {
          case "paincoach":
            await sendPainCoachRemindersTest();
            break;
          case "signup":
            await sendSignUpRemindersTest();
            break;
          case "reassessmentReminders":
            await sendReassessmentEmailsTest();
            break;
          default:
            throw new Error(`Type: ${type} not a valid case for test`);
        }
      } catch (err) {
        this.errorMessage = err.message;
        this.showErrorMessage = true;
      } finally {
        this.notificationReminderInProgress = false;
      }
    },
    async updateAllScores() {
      this.updateInProgress = true;
      this.showErrorMessage = false;

      try {
        // get all assessments in batches
        let allAssessments = [];
        let complete = false;
        let count = 0;
        while (!complete) {
          let returnDto = await getAllAssessmentsForUpdating(count);
          allAssessments = allAssessments.concat(returnDto.assessments);
          complete = returnDto.returnFinished;
          count++;
        }

        let scores = allAssessments.map(x => {
          let results = convertDeskAssessmentData(x);
          let overallScore = DeskAssessmentResults.getOverallScore(results);
          let setupScore = null;

          if (results.setup.deskItems.length > 0) {
            setupScore = Math.round(
              DeskAssessmentResults.getVisualDeskPlannerScore(
                results.setup,
                500
              )
            );
          }

          return {
            assessmentId: x.assessmentId,
            setupScore: setupScore,
            score: overallScore
          };
        });

        complete = false;
        count = 0;
        while (!complete) {
          complete = await updateAssessmentScores(count, { scores: scores });
          count++;
        }
      } catch (err) {
        this.errorMessage = err.message;
        this.showErrorMessage = true;
      } finally {
        this.updateInProgress = false;
      }
    },
    async deleteAssessmentFromId() {
      this.showErrorMessage = false;
      try {
        this.deleteAssessmentInProgress = true;
        await deleteAssessment(this.assessmentIdToDelete);
      } catch (err) {
        this.errorMessage = err.message;
        this.showErrorMessage = true;
      } finally {
        this.deleteAssessmentInProgress = false;
      }
    },
    async moveUsersToNewTeam() {
      this.showErrorMessage = false;
      try {
        if (!this.transferFromTeamId || !this.transferToTeamId) {
          this.errorMessage = "Need to have an ID to transfer to and from";
          this.showErrorMessage = true;
          return;
        }

        this.migrateTeamMembersInProgress = true;

        let dto = {
          transferFrom: this.transferFromTeamId,
          transferTo: this.transferToTeamId
        };

        await moveUsersFromOneTeamToAnother(dto);
      } catch (err) {
        this.errorMessage = err.message;
        this.showErrorMessage = true;
      } finally {
        this.migrateTeamMembersInProgress = false;
      }
    },
    async updateAssessmentDataPoint() {
      this.showErrorMessage = false;

      try {
        this.updatingAssessmentDataPoint = true;
        count = await updateAssesssmentDataPoint(this.updateDataPoint);
      } catch (err) {
        this.errorMessage = err.message;
        this.showErrorMessage = true;
      } finally {
        this.updatingAssessmentDataPoint = false;
      }
    },
    async uploadTeamFile() {
      this.showErrorMessage = false;
      this.updateTeamsUsersObject.userCounter = 0;
      try {
        this.updateTeamsUsersObject.uploadUsersInProgress = true;
        this.updateTeamsUsersObject.removedUsersNotInList = false;
        if (!this.updateTeamsUsersObject.teamIdToUpdate) {
          this.errorMessage = "must enter a team id";
          this.showErrorMessage = true;
          return;
        }

        await removeUsersNotInListDevMethod(
          this.updateTeamsUsersObject.teamIdToUpdate,
          this.updateTeamsUsersObject.usersStillInTeam
        );
        this.updateTeamsUsersObject.removedUsersNotInList = true;
        for (
          var i = 0;
          i < this.updateTeamsUsersObject.convertedUserDtos.length;
          i++
        ) {
          if (
            this.invalidEmail(
              this.updateTeamsUsersObject.convertedUserDtos[i].emailAddress
                .value
            )
          ) {
            this.updateTeamsUsersObject.failedEmails.push(
              this.updateTeamsUsersObject.convertedUserDtos[i].emailAddress
                .value
            );
            continue;
          }
          await updateTeamMemberForTeamDevMethod(
            this.updateTeamsUsersObject.teamIdToUpdate,
            this.updateTeamsUsersObject.convertedUserDtos[i]
          );
          this.updateTeamsUsersObject.userCounter++;
        }
      } catch (exception) {
        this.errorMessage = exception.message;
        this.showErrorMessage = true;
      } finally {
        this.updateTeamsUsersObject.uploadUsersInProgress = false;
      }
    },
    readExcelFile(file) {
      const reader = new FileReader();

      reader.addEventListener("error", () => {
        this.fileErrorMessage = this.$t(
          "invite.viaFile.statusMessages.readError"
        );
      });

      reader.onload = e => {
        // Parse data
        const workBook = XLSX.read(e.target.result, { type: "binary" });
        // Get first worksheet in excel file
        const sheetName = workBook.SheetNames[0];
        const workSheet = workBook.Sheets[sheetName];
        // Convert array of arrays
        this.convertedFile = XLSX.utils.sheet_to_json(workSheet, {
          header: 1,
          defval: ""
        });
      };

      reader.readAsBinaryString(file);
    },
    invalidEmail(email) {
      return !/.+@.+\..+/.test(email);
    },
    showError(err) {
      if (!err) {
        return;
      }
      this.errorMessage = err.message ?? error;
      this.showErrorMessage = true;
    },
    createReader(file, onLoad) {
      addEventListener("error", event => this.showError(event));
      var reader = new FileReader();
      reader.onload = onLoad;
      reader.readAsText(file);
    },
    jsonReadOnLoad(event) {
      this.updateTranslations.readJsonFile = JSON.parse(event.target.result);
      this.createReader(
        this.updateTranslations.translationJsonFileToAdd,
        this.downloadUpdateJSON
      );
    },
    downloadUpdateJSON(event) {
      this.updateTranslations.readJsonFileToAdd = JSON.parse(
        event.target.result
      );
      jsonUpdate.downloadJSON(
        this.updateTranslations.readJsonFileToAdd,
        this.updateTranslations.readJsonFile,
        this.updateTranslations.translationJsonFile.name
      );
    }
  }
};
</script>

<style scoped>
.nukeEffect {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 5;
  background-color: red;
  animation-name: example;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

@keyframes example {
  from {
    background-color: red;
  }
  to {
    background-color: transparent;
  }
}
</style>
